import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import React, { useEffect, useRef, useState } from "react"
import { graphql, PageProps } from "gatsby"
import Layout from "../components/layout"
import { Link } from "../components/utils"
import { NewsListQuery, NewsListQuery_allMicrocmsNews_edges_node as Node } from "./__generated__/NewsListQuery"

export default ({ data, pageContext, location }: PageProps<NewsListQuery, {}>) => {
  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  useEffect(() => {
      const w = window
      const d = document
      const e = d.documentElement
      const g = d.getElementsByTagName("body")[0]

      setWindowWidth(w.innerWidth || e.clientWidth || g.clientWidth);
      setWindowHeight(w.innerHeight || e.clientHeight || g.clientHeight);
  });

  const separateItem = (title: string) => {
    return (
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        iconClassName='bg-bg p-2 w-auto h-auto rounded-none	'
        icon={<div className="text-3xl">{title}</div>}
      >
      </VerticalTimelineElement>
    )
  }

  const contentItem = (item: Node, isEven: boolean) => {
    let date = new Date(item.date);
    return (
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date={
          item.image ?
            <div className="hidden lg:block h-full mx-4 ">
              < img className={`${isEven ? "mr-auto" : "ml-auto"} h-32`}
                src={item.image.url}
              />
            </div>
            : ''
        }
        dateClassName="p-0 h-full top-0 opacity-100"
        // iconClassName='w-1 h-1 ml-5 mt-6 lg:-ml-2px lg:mt-8'
        iconClassName='bg-bg shadow-none w-auto h-auto mt-3 lg:mt-4'
        icon={
          <div className="text-xl lg:text-2xl">
            {`${date.getMonth() + 1}/${date.getDate()}`}
          </div>
        }
        contentStyle={{
          padding: "0",
          background: '0',
          color: '#fff',
          "min-height": "60px",
          "box-shadow": "none",
        }}
        contentArrowStyle={{
          borderRight: `7px solid ${
            windowWidth < 1024 ? 
              'rgb(245, 85, 85)' :
              isEven ? 'rgb(104, 136, 223)' : 'rgb(245, 85, 85)'
          }`,
        }}
      >
        <Link to={item.link}>
          <div className="bg-gradient-primary p-2px">
            {item.image ?
              <div className="lg:hidden">
                < img className=" w-full h-32 object-cover bg-bg"
                  src={item.image.url}
                />
              </div> : ''
            }
            <div className="bg-bg w-full p-4">
              <div className="text-left">
                <div className="text-color-3 lg:text-lg">
                  {item.type}
                </div>
                <div className="lg:text-xl my-1">
                  {item.title}
                </div>
                <div className="text-color-3 text-sm">
                  {item.desc}
                </div>
              </div>
            </div>
          </div>
        </Link>

      </VerticalTimelineElement>
    )
  }

  let list: JSX.Element[] = [];

  const len = list.length;
  let year = null;
  data.allMicrocmsNews.edges.forEach((item, i) => {
    let date = new Date(item.node.date);
    let itemYear = date.getFullYear();
    if (year != itemYear) {
      list.push(separateItem(itemYear.toString()));
      list.push(<span />);
      year = itemYear;
    }
    list.push(contentItem(item.node, list.length % 2 == 0));
  });

  return (
    <Layout
      seo={{
        title: "News",
      }}
      location={location}
    >
      <div className="container mx-auto md:p-12 p-4">
        <div className="title ">
          <h2 className="text-color-1 text-center text-5xl ">
            News
          </h2>
        </div>
        <VerticalTimeline className="min-h-screen">
          {list}
        </VerticalTimeline>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query NewsListQuery {
    allMicrocmsNews(sort: {order: DESC, fields: date})  {
      edges {
        node {
          title
          type
          desc
          date
          image {
            url
          }
          link
        }
      }
    }
  }
`